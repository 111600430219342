@use "../../../App.scss" as app;

.feature-container {
  width: 95vw;
  border-radius: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: app.$color-deep-blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 450px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.feature-container-text {
  max-width: 40vw;
}
@media all and (min-width:1200px) and (max-width: 10000px){
.feature-container-text h1 {
  text-align: left !important;
  font-size: 44px;
  color: white;
}
}
.feature-container-points {
  color: white;
  margin-bottom: 15px;
}

#btn-free-consultation {
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.feature-point-text {
  margin-left: 10px;
  font-weight: 200;
}

.feature-container-graphic-img {
  max-width: 40vw;
  height: auto;
}

.tick-green {
  color: app.$color-green;
}


@media all and (min-width:0px) and (max-width: 1200px) {

  .feature-container {
    width: 95vw;
    border-radius: 20px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: app.$color-deep-blue;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
 
    min-height: 450px;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .feature-container-text {
    max-width: 80%;
    text-align: center ;
    align-items: center;
  }
  
  .feature-container-text h1 {
    
    font-size: 44px;
    color: white;
    position: relative;
    left: 10%;
    text-align: center;
    
  }
  
  .feature-container-points {
    color: white;
    margin-bottom: 15px;
    text-align: center;
  }
  
  #btn-free-consultation {
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .feature-point-text {
   
    font-weight: 200;
    text-align: center;
  }
  
  .feature-container-graphic-img {
    max-width: 40vw;
    height: auto;
    margin-top: 10%;
  }
  
  .tick-green {
    color: app.$color-green;
  }
  
}