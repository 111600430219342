@use "../../App.scss" as app;

#header-parent {
  background-color: white;
  z-index: 99;
  position: sticky;
  top: 0;
}

#header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  max-height: 5vh;
}

#header-branding-img {
  max-height: 5vh;
  height: 50px;
  width: auto;
}

.header-menu-item {
  margin: 10px;
}

#header-btn-contact-us {
  padding-left: 20px;
  padding-right: 20px;

}

hr {
  background-color: #f2f4f7;
  border-color: #f2f4f7;
  color: #f2f4f7;
  margin: 0;
}

#widget {

position: fixed;
top: 10;
right: 0;
}


@media all and (min-width:0px) and (max-width: 1200px) {


  #header-parent {
    background-color: white;
    z-index: 99;
    position: sticky;
    top: 0;
  }
  
  #header-container {
    display: flex;
    flex-direction:row;
 
    justify-content:space-between left;
    padding: 30px;
    max-height: 5vh;
  }
  
  #header-branding-img {
    max-height: 5vh;
    height: 15%;
    width: auto;
  }
  
  .header-menu-item {
   margin-bottom: 2%;
   justify-content: space-between;
   display: block;
   flex-direction: row;
  }
  
  #header-btn-contact-us {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 4%;
    padding-bottom: 4%;
    
  
  }
  #header-menu-pricing{
    font-size: small;
  }  
  hr {
    background-color: #f2f4f7;
    border-color: #f2f4f7;
    color: #f2f4f7;
    margin: 0;
  }
  
  #widget {
  
  left: 0;
  top: 5;
 
  }


}