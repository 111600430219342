@use "../../App.scss" as app;

#home-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#home-features {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 40vw;
  margin-top: 5vh;
  margin-left: 10vw;
  overflow: visible;
}

.home-feature-item {
  font-size: 62px;
  margin: 0px;
  font-weight: 50;
  color: app.$font-color-grey-light;
}

.home-feature-item-bold {
  font-weight: bold;
  color: black;
}

#home-features-action-buttons {
  margin: 20px 5px 20px 5px;
}

.home-features-btn {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
}

#home-features-text {
  margin-top: 10px;
  margin-left: 5px;
  color: app.$font-color-grey;
  font-weight: lighter;
}

#home-feature-company-images {
  margin-top: 30px;
  margin-left: 5px;
}

.home-feature-company-image {
  margin-right: 20px;
  width: 75px;
  height: auto;
}

#home-graphic-image {
  width: 100%;
  height: auto;
  zoom: 300%;
}

#home-feature-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

#home-feature-details h1 {
  font-size: 44px;
  font-weight: bolder;
  max-width: 60vw;
  text-align: center;
}

#home-feature-details-pills {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


@media all and (min-width:0px) and (max-width: 1200px) {



  #home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    
  }
  
  #home-features {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 40vw;
    margin: 0;
    margin-top: 10%;
    // margin-top: 5vh;
    // margin-left: 10vw;
    overflow: visible;
  }
  
  .home-feature-item {
    font-size: 20px;
    // margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    // margin-right: 0;
    font-weight: 50;
    flex-direction: column;
    text-align: center;
    left: 20%;
    color: app.$font-color-grey-light;
  }
  
  .home-feature-item-bold {
    font-weight: bold;
    color: black;
    left: 10%;
  }
  
  #home-features-action-buttons {
    text-align: center;
    flex-direction: column;
    margin-right: 0;
  }
  
  .home-features-btn {
    
    flex-direction: column;
    max-width: 150px;
    text-align: center;
    height: auto;
    margin: 3px;
  }
  
  #home-features-text {
    margin-top: 10px;
    margin-left: 5px;
    text-align: center;
    
    color: app.$font-color-grey;
    font-weight: lighter;
    
  }
  
  #home-feature-company-images {
    margin-top: 40px;
      margin-left: 15%;
    
    align-self: center;
    max-width: 236.8px;
    max-height: auto;


  }
  
  .home-feature-company-image {
    margin: 15px;
    align-items: center;
    width: 70px;
    height: auto;
    // display: none;
  }
  
  #home-graphic-image {
    width: 100%;
    height: auto;
    zoom: 100%;
    display: none;
  }
  
  #home-feature-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
     margin-top: 0px;
     margin-right: 0;
     margin-left: 0;
    background-color: white;
  }
  
  #home-feature-details h1 {
    font-size: 22px;
    font-weight: bolder;
    
    max-width: 80%;
    text-align: center;
    margin-right: 0;
    margin-left: 0;
  }
  
  #home-feature-details-pills {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  


  
}