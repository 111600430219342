@use "../../../App.scss" as app;

.feature-pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 100;
  color: app.$font-color-grey;
  font-size: 15px;
  cursor: pointer;
}

.feature-pill:hover {
  background-color: app.$primary-color-light;
}

.feature-pill-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 100;
  color: app.$primary-color;
  background-color: app.$primary-color-light;
  font-size: 15px;
  cursor: pointer;
}

.feature-pill-text {
  margin-left: 10px;
}
@media all and (min-width:0px) and (max-width: 1200px) {

  .feature-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
    padding: 1px 2px;
    border-radius: 5px;
    text-align: center;
    font-weight: 100;
    color: orangered;
    font-size: 10px;
    cursor: pointer;
  }
  
  .feature-pill:hover {
    background-color: app.$primary-color-light;
  }
  
  .feature-pill-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px;
    padding: 8px 9px;
    border-radius: 5px;
    font-weight: 100;
    color: app.$primary-color;
    background-color: app.$primary-color-light;
    font-size: 11px;
    cursor: pointer;
  }
  
  .feature-pill-text {
    margin-left: 5px;
    text-align: center;
  }

}