$primary-color: #ff5f0a;
$primary-color-dark: #db3f10;
$primary-color-light: #fef6f3;
$font-color-grey: #475467;
$font-color-grey-light: #8e959e;
$color-deep-blue: #1e2022;
$color-green: #12b76a;

// Primary Button Hover
@keyframes onHoverAnimation {
  0% {
    background-color: $primary-color;
  }
  100% {
    background-color: $primary-color-dark;
  }
}

button {
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.btn-primary {
  font-family: inherit;
  background-color: $primary-color;
  color: white;
  // animation-duration: 1s;
  // animation-timing-function: cubic-bezier();
  // animation-iteration-count: infinite;
  // animation-direction: normal;
  // animation-fill-mode: none;
  // animation-play-state: initial;
}

.btn-primary:hover {
  // animation-name: onHoverAnimation;
  background-color: $primary-color-dark;
}

.btn-secondary {
  font-family: inherit;
  background-color: white;
  color: $primary-color;
  border: 1px solid $primary-color;
}

.btn-secondary:hover {
  background-color: $primary-color-light;
}

a {
  color: $font-color-grey;
  text-decoration: none;
}

a:hover {
  color: $primary-color;
}


@media all and (min-width:0px) and (max-width: 1200px) {
  
  button {
    font-size: 10px;
    border: none;
    border-radius: 3px;
    padding: 7px;
    border: 8% solid $primary-color;
    cursor: pointer;
  }
  .btn-secondary {
    font-family: inherit;
    background-color: white;
    color: $primary-color;
    border: 1% solid $primary-color;
  }

}