@font-face {
  font-family: 'Mulish';
  src: url(../public/fonts/Mulish-Regular.woff2);
}

body {
  margin: 0;
  font-family: Mulish, Arial;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
